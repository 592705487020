#tabela-saude table thead tr th{
    padding: 9px 18px !important;
}

#tabela-saude table tbody tr {
    background: #f5f5f5;
}

#tabela-saude .table-responsive {
    /* padding-left:145px; */
    margin-top:14px;
}

/* #tabela-saude table tbody tr td.celulaTabelaValorFaixaEtaria {
    width: 150px;
    min-width: 150px;
    position: absolute;
    left: 0;
} */

/* #tabela-saude table thead tr th.celulaTabelaValorFaixaEtaria {
    width: 150px;
    min-width: 150px;
    height: 550px;
    position: absolute;
    left: 0;
} */

#tabela-saude table tbody tr td.celulaTabelaValor {
    min-width: 100px;
}

