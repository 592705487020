#valorsaude table thead tr th {
    padding: 10px 10px !important;
}

#valorsaude table thead tr th,
#valorsaude table tbody tr td {
    white-space: nowrap;
    vertical-align: middle !important;
}

#valorsaude table tbody tr td {
    padding:0 15px !important;
    width:15%;
}

#valorsaude table .form-group {
    margin-bottom: 0;
}

#valorsaude table input {
    border:0 !important;
}

#valorsaude input[type=text], 
#valorsaude input[type=date], 
#valorsaude input[type=email], 
#valorsaude input[type=password], 
#valorsaude input[type=number] {
    padding:22px 20px;
    background: transparent;
}

.btn-aplicar {
    height: 36px;
    font-size: 14px !important;
    background: var(--primary-color) !important;
    padding:0 20px !important;
    border-radius: 0px !important;
    margin-right: 5px;
    border:1px solid #36769b !important;
    transition: background-color 0.5s !important;
}