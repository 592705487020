#proposta-pessoa-pfpj .tab-body {
    margin-bottom: 15px;
    /* background: #fbfbfb; */
    /* border: 1px solid #dcdcdc; */
    padding: 0;
    border-radius: 8px;
}

#proposta-pessoa-pfpj .select-search__value{
    background: white !important;
}

#proposta-pessoa-pfpj .nav-tabs {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    margin-left: 0;
    margin-right: 0;
    border-radius: 0;
}
#proposta-pessoa-pfpj .nav-tabs a {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #666;
    /* background: #f3f3f3; */
    padding:10px 25px;
    font-size: 13px;
    font-weight: bold;
    border:0;
    border-bottom: 2px solid #dcdcdc;
    /* font-size: 13px; */
    /* border-bottom: 2px solid transparent; */
}

#proposta-pessoa-pfpj .nav-tabs a.active {
    /* font-weight: bold; */
    border-bottom: 2px solid #596d98;
    background-color: white;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    border: 1px solid transparent;
}

#proposta-pessoa-pfpj .nav-tabs a:hover {
    /* font-weight: bold; */
    color:#000;
}

#proposta-pessoa-pfpj .nav-tabs a svg {
    margin-bottom: 8px;
    font-size: 22px;
}

#proposta-pessoa-pfpj .tab-content {
    margin-top:45px;
}

#proposta-pessoa-pfpj .tab-body {
    margin-bottom:15px;
}

#proposta-pessoa-pfpj .styleValor {
    background: #f3f3f3;
    padding: 15px;
    border: 2px solid #dcdcdc;
    margin: 0;
}

#proposta-pessoa-pfpj .styleValor input{
    background: transparent;    
}

@media (max-width: 992px) { 

    #proposta-pessoa-pfpj .styleValor input{
        background: transparent !important;    
    }  
}

#proposta-pessoa-pfpj .dependente {
    padding: 0 29px;
    margin: 42px 0;
    /* border-left: 4px solid; */
    background: transparent;
    position: relative;
}

#proposta-pessoa-pfpj .dependentes {
    position: relative;
    padding: 1px 30px;
}

#proposta-pessoa-pfpj .dependentes:before {
    top: 0;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 4px;
    /* background-color: #596d98; */
    left: 10px;
    margin-left: -1.5px;
}

#proposta-pessoa-pfpj .dependentes .dependente .btn-excluir-dependentes {
    color: white;
    border-radius: 0;
    font-size: 14px;
  }

#proposta-pessoa-pfpj .btn-add-dependente {
   height: 36px;
   font-size: 14px !important;
   background: #596d98 !important;
   padding:0 20px !important;
   border-radius: 0px !important;
   border:1px solid #36769b !important;
   transition: background-color 0.5s !important;
   margin-left: 9px;
}
  
#proposta-pessoa-pfpj .btn-add-dependente:hover {
    background: #3f4c69 !important;
    transition: background-color 0.5s !important;
}

#proposta-pessoa-pfpj .btn-add-dependente svg {
    float:left;margin:3px 4px 0 0;
}

#proposta-pessoa-pfpj .btn-add-dependente-plus {
    height: 36px;
    width: 36px;
    font-size: 14px !important;
    background: #596d98 !important;
    /* padding:0 20px !important; */
    border-radius: 50% !important;
    border:1px solid #36769b !important;
    transition: background-color 0.5s !important;
    margin-left: -6px;
}
   
#proposta-pessoa-pfpj .btn-add-dependente-plus:hover {
     background: #3f4c69 !important;
     transition: background-color 0.5s !important;
}
 
#proposta-pessoa-pfpj .btn-add-dependente-plus svg {
     float:left;
     margin: 0 0 0 -6px;
     font-size: 22px;
}

#proposta-pessoa-pfpj .dependente h4:after {
    content: '';
    background-color: white;
    border: 4px solid #FF9F55;
    border-radius: 50%;
    position: absolute;
    left: -28px;
    top: 4px;
    height: 19px;
    width: 19px;
  }

#proposta-pessoa-pfpj .dependente input{
    background: #fff;
}

#proposta-pessoa-pfpj .toast.show {
    position: absolute;
    right: 5px;
    top: 18px;
    z-index: 1;
}

#proposta-pessoa-pfpj .toast-header {
    color: #ffffff;
    background-color: rgb(216 74 74 / 85%);
    border-radius: 0;
    font-size:12px;
    display: flex;
    justify-content: space-between;
}

#proposta-pessoa-pfpj .toast-header svg{ 
    cursor: pointer;
}

#proposta-pessoa-pfpj .toast-body {
    background-color: #fdc1c1;
    font-size: 11px;
    color: black;
}
/*
#dados-proposta-tabpane-proposta .tab-body .row .profileDetail div {
    padding: 10px 25px;
}

#dados-proposta-tabpane-proposta .tab-body .row .profileDetail div label {
    border-bottom: 1px solid rgba(0,0,0,.125);
    margin-bottom: 8px;
    padding-bottom:2px;
    width: 100%;
}*/

#dados-proposta-tabpane-proposta .tab-body.profileDetail .profileDetail-box,
#dados-proposta-tabpane-informacoes .tab-body.profileDetail .profileDetail-box,
#dados-proposta-tabpane-comissao .tab-body.profileDetail .profileDetail-box,
#dados-proposta-tabpane-pendencias .tab-body.profileDetail .profileDetail-box {
    /* border: 1px solid #dcdcdc; */
    /* background: #f3f3f3; */
    margin: 0 0 10px 0;
    padding: 5px 10px 5px 15px;
    /* border-left: 5px solid; */
}


#dados-proposta-tabpane-proposta .tab-body .row .profilePicture {
    text-align: center;
    padding-top:25px;
    border-top: 6px solid rgba(0,0,0,.125);
    background: rgb(156 151 151 / 13%);
}

@media (max-width: 767px) {
    #dados-proposta-tabpane-proposta .tab-body .row .profileDetail div {
        padding: 10px 0;
    }
}


#proposta-pessoa-pfpj .tool-tip {
    position: relative;
    cursor: help;
}

#proposta-pessoa-pfpj .tool-tip:hover p.tip {
    opacity: 1;
    bottom: 12.8rem;
}

#proposta-pessoa-pfpj .tool-tip p.tip {
    opacity: 0;
    user-select: none;
    pointer-events: none;
    position: absolute;
    left: 0;
    bottom: 12.8rem;
    font-size: .7rem;
    padding: .4rem .4rem .45rem .4rem;
    background:rgba(36, 36, 36, .8);
    border-radius:4px;
    border:1px solid #dcdcdc;
    color: #fff;
    width: 100%;
    transition: opacity .2s, top .2s;
}

#proposta-pessoa-pfpj .tool-tip p.tip:before {
    content:'';
    display:block;
    width:0;
    height:0;
    position:absolute;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid rgba(36, 36, 36, .8);
    left:45%;
    bottom:-8px;
}


#proposta-pessoa-pfpj .box-file-item .config-file {
    font-weight: bold;
}

#proposta-pessoa-pfpj .box-file-options:hover svg {
    filter: grayscale(0);
}

#proposta-pessoa-pfpj .box-file-options svg {
    filter: grayscale(100%);
    transition: filter .2s;
    margin-left:8px;
}