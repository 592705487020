.btns-table {
    padding-left: 55px;
    opacity: 0;
    display: flex;
    align-items: center;
}
.btns-table button {
    display: flex;
    justify-content: flex-end;
    flex: 1;
}

table.table tbody tr:hover td:first-child .btns-table{
    opacity: 1;
    transition: .85s;
}
