#section-vendas-chart .title-chart {
    /* border-bottom: 1px solid #dcdcdc; */
    margin-top: 20px;
    padding-bottom: 5px;
    font-size: 16px;
}

#section-vendas-chart .title-chart svg{
    float:left;
    margin: 1px 2px;
}

#section-vendas-chart .select-search input:focus {
    border-color: transparent;    
}

#section-vendas-chart .custom-btn {
    background: rgb(255, 255, 255) !important;
    border: 1px solid rgb(230, 230, 230) !important;
    height: 45px;
    margin-bottom: 0;
    color: #000;
    text-align: left;
}

#section-vendas-chart table.table thead tr th {
    padding: 12px 17px !important;
}