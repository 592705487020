#consultar-simulador .btn-grupo button[type="button"] {
    /* width: 100%;*/
    height: 36px;
    font-size: 14px !important;
    background: var(--primary-color) !important;
    padding:0 20px !important;
    border-radius: 0px !important;
    /* margin-bottom:25px; */
    border:1px solid #36769b !important;
    transition: background-color 0.5s !important;
}

#consultar-simulador .btn-grupo button[type="button"]:hover {
    background: #3f4c69 !important;
    transition: background-color 0.5s !important;
}

#consultar-simulador .btn-grupo button[type="button"] svg {
    float:left;margin:3px 4px 0 0;
}

#consultar-simulador tr td{
    vertical-align: middle;
}

#consultar-simulador tr.headerTable td{
    background-color:#596d98 !important;
    border:0;
    color:white;
}

#consultar-simulador .separateRow {
    border: 1px solid transparent;
    background: #fff;
    height: 30px;
}

#consultar-simulador tr.headerTableOperadora td{
    background-color: #e4e4e4 !important;
    color: #000;
    font-weight: bold;
    border-left:6px solid #FF9F55;
}
