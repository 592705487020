.login-container {
    width: 100%;
    height: 100vh;
    margin: 0 auto;
    background-color: #ebeeef;
}
.container {
    margin:0;
    padding:0;
}

.login-container .box-login {
    justify-content: center;    
    align-items: center;
    height: 100vh;
    display: flex;
}

.login-container .box-login .box-credenciais {
    width: 490px;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border:1px solid #dcdcdc;
}

.login-container .box-login .box-credenciais .box-title { 
    background-image: url(./../../assets/bg-top.jpg);
    width: 100%;
    position: relative;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 40px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.login-container .box-login .box-credenciais .box-title::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgb(89 109 152 / 86%);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.login-container .box-login .box-credenciais .box-subtitle {
    font-size: 30px;
    color: #fff;
    text-transform: uppercase;
    line-height: 1.2;
    text-align: center;
}

.login-container .box-login .box-credenciais .box-subtitle small{
    letter-spacing: 11px;
}

.login-container .box-login .box {
    padding: 55px 90px;
    width: 100%;
}

.login-container .box-login .box .logo
{
    text-align: center;
    height: 100px;
    display: flex;
    justify-content: center;
}

.login-container .box-login .box .logo img 
{
    max-width: 100%;
    max-height: 100%;
}

.login-container .box-login .version
{
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    margin-top:20px;
}

.btn-acessar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
}

.login-container form button {
    height: 46px;
    background: var(--primary-color) !important;
    border-radius: 5px !important;
    border:1px solid #36769b !important;
    transition: background-color 0.5s !important;
    padding: 20px 20px !important;
}

.login-container form button:hover {
    background: #3f4c69 !important;
    transition: background-color 0.5s !important;
}

.login-container form input[type=text],
.login-container form input[type=email],
.login-container form input[type=password],
.login-container form input[type=number] {
    flex: 1;
    background: #fdfdfd;
    border-radius: 0px;
    border: 0;
    padding: 24px 40px;
    font-size: 16px;
    color: #6C6C80;
    border:1px solid #cfcfdb;
}

.colButton {
    background:#ececec !important;
    cursor: pointer;
    border: 1px solid transparent !important;
    font-weight: bold;
}

@media (max-width: 992px) { 

    .login-container form input[type=text],
    .login-container form input[type=email],
    .login-container form input[type=password],
    .login-container form input[type=number] {
        background: #FFF !important;
        padding: 24px 38px !important; 
    }   

    .login-container .form-group svg {
        top: 26px !important;
    }

    .login-container .box-login .box-msg {
        display: none !important;
    }

    .login-container .box-login .box-credenciais  {
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .login-container .box-login .box .logo img {
        padding-top:0px;
        margin-bottom:20px;
    }

    .login-container .box-login .box {
        background-color: rgba(255, 255, 255, 0) !important;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0) !important;
    }
}

@media (max-width: 600px){
    .login-container .box-login .box-credenciais  {
        width: 100%;
    }
    .login-container .box-login .box {
        padding: 50px;
    }
    .login-container .box-login .box .logo h1 {
        font-size:25px;
    }
}

/*
Inputs
*/

.login-container .form-group {
    position: relative;
}

.login-container .form-group svg {
    position: absolute;
    top: 26px;
    left: 1rem;
    transform: translateY(-50%);
    color: #acacac;
    transition: color .2s;
}

.login-container .form-group:focus-within svg {
    color: #393838;
}

.login-container .form-group input {
    font-family: inherit;
    font-size: 0.9rem !important;
    transition: border-color .2s, box-shadow .2s;
}

.login-container .form-group input::placeholder {
    color:#acacac !important;
    user-select: none;
}

.login-container .form-group input:focus {
    border-color: #808080;
    box-shadow: 0 0 0 .1rem rgba(89, 109, 152, 0);
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 11px;
    color: #dc3545;
}
