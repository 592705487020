#consultar-metas tr.headerTable th{
    padding:10px 18px !important;
}

#consultar-metas tr.headerTableMetas td{
    background-color: #e4e4e4 !important;
    color: #000;
    font-weight: bold;
}

#consultar-metas tr.headerTableMetas td svg{
    float:left;
    margin: 3px 4px 0 0;
}