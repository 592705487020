.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
}

.files {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 30px;
}

.files .box-file {
    position: relative;
    height: 212px;
    max-width: 216px;
    background: white;
    border-radius: 10px;
    border: 1px solid #DCDCDC;
}

.files .box-file .box-file-item {
    display: flex;
    justify-content: center;
    margin-top: 19px;
}

.files .box-file .box-file-item img {
    border-radius: 7px;
}

.files .box-file .box-file-item svg {
    margin-top: 6px;
}

.files .box-file .box-file-options {
    /* position: absolute;
    display:flex;
    justify-content: center;
    align-items: center;
    transition: .5s ease;
    opacity: 0;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center; */
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .5s ease;
    opacity: 0;
    border-radius: 10px;
    /* width: 100%; */
    /* height: 67px; */
    /* top: 84%; */
    right: -8px;
    bottom: 4px;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.files .box-file:hover .box-file-options {
    opacity: 1;
    /* background: rgba(0, 0, 0, 0.7); */
}

.files .box-file .box-file-options svg {
    cursor: pointer;
} 

.files .config-file {
    position: absolute;
    border-radius: 0 0 10px 10px;
    bottom: 0;
    width: 100%;
    display: flex;
    font-size:12px;
    flex-direction: column;
    padding: 10px;
    background-color: rgba(185, 183, 183, 0.3);
}

.loadingFile {
    cursor: not-allowed;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: none;
    justify-content: center;
    align-items: center;
    background: rgb(47 47 47 / 50%);
    color: #fff;
    border-radius: 10px;
    flex-direction: column;
}

.detalhe-proposta-pessoa-pfpj .dependente .row .col-md-3 {
    padding: 0 0 16px 15px;
}

.profileDetail-box svg {
    font-size: 14px;
    margin-left: 5px;
    margin-top: -3px;
    cursor: pointer;
}