table .spinner-border-sm {
    position: absolute;
    top: 15px;
    font-size: 16px;
    left: 5px;
    color: #727272;
    transition: color .2s;
    z-index: 1;
    border-width: 2px;
}

#operadora table>tbody>tr.headerTable {
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
    font-weight: bold;
    font-size: 12px;
}

#operadora table>tbody>tr.headerTable>td {
    vertical-align: middle;
    padding: 15px 18px !important;
}

#operadora table>tbody>tr>td:not(.headerTable>td) {
    background-color: #f7f7f7;
}
