:root {
    --default-color: #ffffff;
    --primary-color: #596d98;
    --background-color: #F0F0F5;
    --title-color: #322153;
    --text-color: #6C6C80;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    /* background: var(--default-color); */
    background: #ecf0f3;
    -webkit-font-smoothing: antialiased;
    color: var(--text-color);
    font-size:.9em;
  }
  
  /* body, input, button {
    font-family: Roboto, Arial, Helvetica, sans-serif;
  } */
  
  h1, h2, h3, h4, h5, h6 {
    color: var(--title-color);
    /* font-family: Ubuntu; */
  }

/* Geral */

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {   
  border-color: #e4e4e4;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.075) inset, 0 0 0 rgba(126, 239, 104, 0.6);
  outline: 0 none;
}

@media (max-width: 992px) { 

    form input[type=date]
    {
        background: #FFF !important;
        padding: 22px 14px !important; 
        border-radius: 0px !important;
    } 

    form input[type=text],
    form input[type=email],
    form input[type=password],
    form input[type=number]
     {
        background: #FFF !important;
        border-radius: 0px !important;
    }    
}

main {
    margin-bottom: 40px;
}

input[type=date] {
    flex: 1;
    background: #fdfdfd;
    border-radius: 0px !important;
    border: 0;
    padding: 22px 10px;
    font-size: 14px;
    color: #6C6C80;
    border:1px solid #cfcfdb;
}

input[type=text],
input[type=email],
input[type=password],
input[type=number] {
    flex: 1;
    background: #fff;
    border-radius: 0px !important;
    border: 0;    
    font-size: 13px !important;
    color: #000;
    border:1px solid #e4e4e4;
}
input[type=date] {
    font-size: 13px !important;
    color: #000;
}
select {
    background: #fdfdfd !important;
    border-radius: 0 !important;
    height: 40px !important;
    font-size: 13px !important;
    color: #000;
}

.btn.focus, .btn:focus {
    box-shadow: none;
}

.dropdown-toggle::after {
    display: none;
}

button[type="submit"],
.btn-send {
    height: 36px;
    font-size: 14px !important;
    background: var(--primary-color) !important;
    padding: 6px 20px 20px !important;
    border-radius: 5px !important;
    border:1px solid #36769b !important;
    transition: background-color 0.5s !important;
    color: #fff;
}

button[type="submit"]:hover,
.btn-send:hover {
    color: #fff;
    background: #3f4c69 !important;
    transition: background-color 0.5s !important;
}

button[type="submit"] svg,
.btn-send svg {
    float:left;
    margin: 4px 4px 0 0;
}

button[type=reset] {
    color:#666;
    height: 36px;
    background: #e6e6e6 !important;
    border-radius: 5px !important;
    border:1px solid #cacaca !important;
    transition: background-color 0.5s !important;
}
button[type=reset]:active, 
button[type=reset]:focus {
    color:#666 !important;
}
button[type=reset]:hover {
    color:#666;
    background: #dadada !important;
    transition: background-color 0.5s !important;
}

button[type=reset] svg {
    float:left;
    margin: 5px 4px 0 0;
}

button.padrao-cancelar {
    color:#666;
    height: 36px;
    background: none !important;
    border-radius: 0px !important;
    border:0px solid #cacaca !important;
    transition: color 0.5s !important;
}
button.padrao-cancelar:active, 
button.padrao-cancelar:focus {
    color:#666 !important;
}
button.padrao-cancelar:hover {
    color:#000;
    background: none !important;
    transition: color 0.5s !important;
}

button.padrao-cancelar svg {
    float:left;
    margin:5px 4px 0 0;
}

.padrao {
    /* width: 100%;*/
    height: 36px;
    background: var(--primary-color) !important;
    padding:0 20px !important;
    border-radius: 0px !important;
    margin-bottom:25px;
    border:1px solid #36769b !important;
    transition: background-color 0.5s !important;
}

.padrao:hover {
    background: #3f4c69 !important;
    transition: background-color 0.5s !important;
}

.padrao svg {
    float:left;margin:3px 4px 0 0;
}

.card {
    border: 0px solid transparent;
    box-shadow: 13px 13px 20px #cbced1, -13px -13px 20px #f5f5f5;
    border-radius: 11px;
    padding: 11px 25px 20px 25px;
}

.dropdown-item {
    font-size: 12px;
}

.dropdown-item.active, .dropdown-item:active {
    background-color: #596d98;
}

.card .card-body {
    padding:0;
}

.card>hr {
    margin:0;
    margin-bottom: 10px;
}

.input-group-text {
    border-radius: 0;
}

/* table.table {
    border-collapse: separate; 
    border-spacing: 5px;
} */

table.table tr td {
    padding: .75rem;
    border-top: none;
    border: 1px solid #dcdcdc;
    background-color: #fbfbfb;
}

table.table tbody tr td {
    padding: 8px 18px !important;
}

table.table thead tr th {
    padding: 0 0 0px 13px !important;
    vertical-align: middle;
    text-align: left;
    border:1px solid hsl(0, 0%, 86%);
    background-color: #f1f1f1;
    color: #666;
    font-size: 13px;
}

table.table thead tr th input,
table.table thead tr th input:focus{
    background: transparent;
}

table.table tbody tr {
    border:1px solid transparent;
    font-size: 13px;;
}

/* table.table tbody tr td:first-child {
    border-left:6px solid #596d98;
} */

.pagination {
    display: flex;
    justify-content: center;
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
    z-index: 3;
    color: #fff;
    background-color: #596d98;
    border-color: #596d98;
    cursor: default;
}

.pagination > li > a, .pagination > li > span {
    position: relative;
    float: left;
    padding: 6px 12px;
    line-height: 1.42857143;
    text-decoration: none;
    color: #596d98;
    background-color: #fff;
    /* border: 1px solid #ddd; */
    margin-left: 3px;
    border-radius: 50%;
}

.pagination > li > a:hover{
    background:#ddd;
}

.botao
{
    background:rgb(0, 0, 0, 0) !important;
    margin:0;
    padding:0 !important;
    height:auto;
    border-color:rgb(0, 0, 0, 0) !important;
    outline:none !important;
}

.botao:hover
{
    background:rgb(0, 0, 0, 0) !important;
}

.swal2-cancel {
    background: #e6e6e6 !important;
    color:#666 !important;
    border:1px solid rgb(204, 204, 204) !important;
}

.swal2-cancel:hover {
    background: #cfcfcf !important;
    transition: background-color 0.5s !important;
}

/*
Toast
*/
.toast {
    min-width: 250px;
    background-color: rgba(255,255,255,1);
    border-radius: 0;
}

.toast-header button.close {
    display: none !important;
}

.toast-body {
    background-color: #fff;
}

.toast-group {
    display: grid;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 9;
}

/*
Inputs
*/



.field-search .form-group {
    margin-bottom: 0;
}

table thead tr th .form-group {
    margin: 0;
    flex: 1;
}

table thead tr th .form-group svg {
    top: 22px;
    left: 0;
}

table thead tr th .form-group input {
    border:0;
}

table thead tr th .form-group input::placeholder {
    color: #666 !important;
    opacity: 1; /* Firefox */
    font-weight: bold;
    font-size:13px;
  }
  
table thead tr th .form-group input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #666 !important;
    opacity: 1; /* Firefox */
    font-weight: bold;
    font-size:13px;
}

table thead tr th .form-group input::-ms-input-placeholder { /* Microsoft Edge */
    color: #666 !important;
    opacity: 1; /* Firefox */
    font-weight: bold;
    font-size:13px;
}

table thead tr th input[type=text] {
    padding: 22px 25px;
}

.form-group {
    position: relative;
}

label{
    font-size: 11px;
    font-weight: bold;
    margin-bottom: 0;
    color: #524c4c;
}

.form-control {
    padding:22px 17px;
}

table thead>tr>th .form-group svg:not(#proposta-pessoa-pfpj .form-group svg) {
    position: absolute;
    top: 23px;
    font-size: 16px;
    left: 5px;
    transform: translateY(-50%);
    color: #acacac;
    transition: color .2s;
    z-index: 1;
}

table thead>tr>th .form-group:focus-within svg:not(#proposta-pessoa-pfpj .form-group:focus-within) {
    color: #393838;
}

table thead>tr>th .form-group input:not(#proposta-pessoa-pfpj .form-group input) {
    font-family: inherit;
    transition: border-color .2s, box-shadow .2s;
}

table thead>tr>th .form-group input::placeholder:not(#proposta-pessoa-pfpj .form-group input::placeholder) {
    color:#acacac !important;
    user-select: none;
}

table thead>tr>th .form-group input:focus:not(#proposta-pessoa-pfpj .form-group input:focus) {
    border-color: #808080;
    box-shadow: 0 0 0 .1rem rgba(89, 109, 152, 0);
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 11px;
    color: #dc3545;
}

.breadcrumb {
    margin-bottom: 0 !important;
}

/*
Swal2
*/
.swal2-title {
    font-size: 16px !important;
}
.swal2-content {
    font-size:13px !important;
}
.swal2-styled {
    padding: 7px 15px !important;
}
.swal2-styled.swal2-confirm {
    background-color: #596d98 !important;
}

/*
    Dropdown
*/

.dropdown-menu {
    transform: translate(-155px, 10px) !important;
}

.dropdown-menu a {
    padding: 5px 15px;
    display: flex;
    align-items: center;
}

.dropdown-menu a:hover {
    background-color: #ebebeb;
}

.dropdown-item.active, .dropdown-item:active {
    background-color: #ebebeb;
    color:#000;
}

/* Adicionar Arrow */
/*
.dropdown-menu::before {
    border-bottom: 9px solid rgba(0, 0, 0, 0.2);
    border-left: 9px solid rgba(0, 0, 0, 0);
    border-right: 9px solid rgba(0, 0, 0, 0);
    content: "";
    display: inline-block;
    right: 7%;
    position: absolute;
    top: -8px;
}

.dropdown-menu::after {
    border-bottom: 8px solid #FFFFFF;
    border-left: 9px solid rgba(0, 0, 0, 0);
    border-right: 9px solid rgba(0, 0, 0, 0);
    content: "";
    display: inline-block;
    right: 7%;
    position: absolute;
    top: -7px;
}*/

/*
    Date Picker 100% width
*/
.react-datepicker-wrapper {
    display: flex !important;
}
.react-datepicker-popper {
    z-index:2 !important;
}

.react-datepicker__header select {
    background: #fdfdfd !important;
    border-radius: 4px !important;
    font-size: 13px !important;
    color: #000;
    padding: 8px 11px;
    border: 1px solid #dcdcdc;
    margin-top:13px;
}

/*
    Select
*/
.select-search__select {
    max-height: 200px !important;
}
/**
 * Main wrapper
 */
 .select-search {
    position: relative;
    box-sizing: border-box;
    padding: 0;
    border: 1px solid #e4e4e4;
}

.select-search *,
.select-search *::after,
.select-search *::before {
    box-sizing: inherit;
}

/**
 * Value wrapper
 */
.select-search__value {
    position: relative;
    z-index: 1;
    padding: 4px;
}

.select-search__value::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: calc(50% - 5px);
    right: 19px;
    width: 7px;
    height: 7px;
}

/**
 * Input
 */
.select-search__input {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 33px 0 10px;
    background: #fff;
    border: 1px solid transparent;
    outline: none;
    font-size: 13px;
    text-align: left;
    text-overflow: ellipsis;
    /* line-height: 36px; */
    -webkit-appearance: none;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
    -webkit-appearance:none;
}

.select-search__input:not([readonly]):focus {
    cursor: initial;
    border: 1px solid transparent;
}

/**
 * Options wrapper
 */
.select-search__select {
    background: #fff;
    margin:-1px;
    /* box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15); */
    border:1px solid #cfcfdb;
}

/**
 * Options
 */
.select-search__options {
    list-style: none;
    padding: 0;
    margin: 0;
}

/**
 * Option row
 */
.select-search__row:not(:first-child) {
    border-top: 1px solid #eee;
}

/**
 * Option
 */
.select-search__option,
.select-search__not-found {
    display: block;
    /*height: 36px;*/
    width: 100%;
    padding: 5px 16px;
    background: #fff;
    border: none;
    outline: none;
    font-size: 12px;
    text-align: left;
    cursor: pointer;
}

.select-search--multiple .select-search__option {
    height: 48px;
}

.select-search__option.is-selected {
    background: #afafaf;
    color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
    background: rgba(187, 187, 187, .5);
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
    background: #797979;
    color: #fff;
}

/**
 * Group
 */
.select-search__group-header {
    font-size: 10px;
    text-transform: uppercase;
    background: #eee;
    padding: 8px 16px;
}

/**
 * States
 */
.select-search.is-disabled {
    opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
    background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
    cursor: pointer;
}

/**
 * Modifiers
 */
.select-search--multiple {
    border-radius: 3px;
    overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
    transform: rotate(45deg);
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    pointer-events: none;
}

.select-search--multiple .select-search__input {
    cursor: initial;
}

.select-search--multiple .select-search__input {
    /* border-radius: 3px 3px 0 0; */
    border-color: transparent;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
    cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
    border-color: transparent;
}

.select-search:not(.select-search--multiple) .select-search__select {
    position: absolute;
    z-index: 2;
    top: 44px;
    right: 0;
    left: 0;
    /* border-radius: 3px; */
    overflow: auto;
    max-height: 360px;
}

.select-search--multiple .select-search__select {
    position: relative;
    overflow: auto;
    max-height: 260px;
    border-top: 1px solid #eee;
    /* border-radius: 0 0 3px 3px; */
}

.select-search__not-found {
    height: auto;
    padding: 16px;
    text-align: center;
    color: #888;
}

.select-search.is-invalid, .was-validated .select-search:invalid {
    border-color: #dc3545;
}

.form-control.is-invalid:focus, .was-validated .form-control:invalid:focus {
    box-shadow: 0 0 0 0.1rem rgba(89, 109, 152, 0) !important;
}

/*
    Modal
*/

.modal-header {
    border-bottom: 1px solid transparent; 
}

.modal-footer {
    border-top: 1px solid transparent;
}

/*
    React Select
    Table Format
*/

table>thead>tr .basic-single {
    right: 6px;
}

table>thead>tr .basic-single > div {
    background-color: transparent;
    border-color: transparent;
    box-shadow: 0 0 0 0 transparent !important;
}

table>thead>tr .basic-single > div:hover {
    border-color: transparent;
    box-shadow: 0 0 0 0 transparent;
    background-color: transparent;
}

.select-default {
    transition: border-color .10s ease-in-out, box-shadow .10s ease-in-out;
    border:1px solid transparent;
}

.select-default > div:nth-child(2) {
    background-color: #fff;
    border: 1px solid #e4e4e4;
    box-shadow: 0 0 0 0 transparent !important;
    border-radius: 0;
    padding: 4px 0;
}

.select-default > div:hover {
    border-color: #e4e4e4 !important;
    box-shadow: 0 0 0 0 transparent;
    background-color: #fff;
}

/*
    Is invalid
*/
.select-default.is-invalid {
    border-color:  #dc3545;
}

.select-default.is-invalid:focus {
    box-shadow: 0 0 0 0.1rem rgba(89, 109, 152, 0) !important;
}

.select-default.is-invalid > div {
    border: 1px solid transparent;
}

#main-container {
    position: relative;
    background-color: black;
    height: 100vh;
    min-height: 25rem;
    width: 100%;
    overflow: hidden;
    margin-bottom: 0 !important;
}

#main-container video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

#main-container .container {
    position: relative;
    z-index: 2;
    align-items: center;
    margin: 55px auto;
    height: 100vh;
}

#main-container .container div.title {
    background-color: #4887a9;
    padding:10px 25px;
    color:white;
    font-weight: bold;
}

#main-container .container div.info{
    border:1px solid #6f6f6fcf;
    align-items: center;
    padding: 25px;
    color: #fff;
    font-weight: 400;
    display: flex;
    background: #00000070;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#main-container .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.6;
    z-index: 1;
}

#main-container .container div.button {
    display: flex;
    justify-content: center;
}

#main-container .container div.button a{
    position: relative;
    display: inline-block;
    padding: 25px 30px;
    margin: 40px 0;
    color: #ffffff;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.5s;
    font-weight: 600;
    letter-spacing: 2px;
    overflow: hidden;
    margin-right: 50px;
}

#main-container .container div.button a > svg {
    font-size: 23px;
    float: left;
    margin-right: 11px;
    margin-top: -1px;
}

#main-container .container div.button a:hover{
    background: #4887a9;
    color: #FFFFFF;
    box-shadow: 0 0 5px #4887a9,
                0 0 25px #4887a9,
                0 0 50px #4887a9,
                0 0 200px #4887a9;
     -webkit-box-reflect:below 1px linear-gradient(transparent, #0005);
}
/*
#main-container .container div.button a:nth-child(1){
    filter: hue-rotate(270deg);
}
*/
#main-container .container div.button a:nth-child(2){
    filter: hue-rotate(110deg);
}
#main-container .container div.button a span{
    position: absolute;
    display: block;
}
#main-container .container div.button a span:nth-child(1){
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg,transparent,#fff);
    animation: animate1 1s linear infinite;
}
@keyframes animate1{
    0%{
        left: -100%;
    }
    50%,100%{
        left: 100%;
    }
}
#main-container .container div.button a span:nth-child(2){
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg,transparent,#fff);
    animation: animate2 1s linear infinite;
    animation-delay: 0.25s;
}
@keyframes animate2{
    0%{
        top: -100%;
    }
    50%,100%{
        top: 100%;
    }
}
#main-container .container div.button a span:nth-child(3){
    bottom: 0;
    right: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg,transparent,#fff);
    animation: animate3 1s linear infinite;
    animation-delay: 0.50s;
}
@keyframes animate3{
    0%{
        right: -100%;
    }
    50%,100%{
        right: 100%;
    }
}

#main-container .container div.button a span:nth-child(4){
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg,transparent,#fff);
    animation: animate4 1s linear infinite;
    animation-delay: 0.75s;
}
@keyframes animate4{
    0%{
        bottom: -100%;
    }
    50%,100%{
        bottom: 100%;
    }
}