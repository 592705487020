header {
    padding-bottom: 15px !important;
}

.cabecalho {
    width: 100%;
    background: #596d98;
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
}

.cabecalho .optionsUser a{
    color:white;
    padding: 0 10px;
}

.cabecalho .userLoggedIn {
    color: white;
    font-size: 16px;
}

.cabecalho .userLoggedIn svg{
    float: left;
    margin-top: 3px;
    margin-right: 5px;
}

#menu ul {
    margin: 0;
    padding: 0;
}

#menu .main-menu {
    display: none;
}

#tm:checked + .main-menu {
    display: block;
}

#menu input[type="checkbox"],
#menu ul span.drop-icon {
    display: none;
}

#menu li,
#toggle-menu,
#menu .sub-menu {
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.1);
}

#menu li,
#toggle-menu {
    border-width: 0 0 1px;
}

#menu .sub-menu {
    background-color: #fff;
    -webkit-box-shadow: 3px 3px 1px 0px rgba(168, 168, 168, 7);
    -moz-box-shadow: 3px 3px 1px 0px rgba(168, 168, 168, 0.7);
    box-shadow: 3px 3px 1px 0px rgba(168, 168, 168, 0.7);
    border-width: 1px 1px 0;
}

#menu .sub-menu li:last-child {
    border-width: 0;
}

#menu li,
#toggle-menu,
#menu a {
    position: relative;
    display: block;
    color: #666;
    font-size: 13px;
    text-decoration: none;
    cursor: pointer;
    background-position: center;
    transition: background 0.8s;
}

#menu li:hover,
#menu a:hover {
    background: #ececec radial-gradient(circle, transparent, #ececec 1%)
        center/10000%;
    transition: background 0.8s;
}
#menu li:active,
#menu a:active {
    background-color: #cfcfcf !important;
    background-size: 100%;
    transition: background 0s;
}

#menu div.item-menu svg {
    float: left;
    margin: 3px 2px 0 0;
}

#menu ul.main-menu li a svg {
    float: left;
    margin: 3px 2px 0 0;
}

#menu div.item-menu .drop-icon {
    margin-left: 3px;
}

#menu {
    border-bottom: 1px solid #dcdccd !important;
    height: 45px;
}
#menu,
#toggle-menu {
    background-color: #fff;
}

#toggle-menu,
#menu a,
#menu div.item-menu {
    padding: 1em 1.5em;
}

#menu a {
    transition: all 0.125s ease-in-out;
    -webkit-transition: all 0.125s ease-in-out;
}

#menu a:hover {
    background-color: #ececec;
}

#menu .sub-menu {
    display: none;
}

#menu input[type="checkbox"]:checked + .sub-menu {
    display: block;
}

#menu .sub-menu a:hover {
    color: #444;
}

#toggle-menu .drop-icon,
#menu li label.drop-icon {
    position: absolute;
    right: 0em;
    top: 1.25em;
    padding-right: 15px;
}

#menu label.drop-icon,
#toggle-menu span.drop-icon {
    border-radius: 50%;
    width: 100%;
    margin-top: -15px;
    height: 47px;
    text-shadow: 0 0 0 transparent;
    color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

#menu .drop-icon {
    line-height: 1;
}

#menu ul li .menu-right {
    float: right !important;
}

@media only screen and (max-width: 1024px) and (min-width: 320px) {
    #toggle-menu {
        padding-bottom: 2.3em;
        border-bottom: 0;
    }
    .info a {
        font-size: 10px !important;
    }
    #menu li:hover,
    #menu a:hover {
        background: #ececec;
    }
    #menu li:active,
    #menu a:active {
        background-color: #dce0e9 !important;
    }

    #menu ul li a i {
        padding-right: 19px;
    }

    #tm:checked + .main-menu {
        display: block;
        margin-top: 57px;
        float: left;
        width: 100%;
    }

    #menu .main-menu {
        position: absolute;
        width: 100%;
        top: 32px;
        z-index: 9999999;
        background: white;
        border-bottom: 2px solid #dcdcdc;
    }
    #menu .sub-menu {
        background-color: #fff;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        border-width: 0px;
    }

    #menu li {
        width: auto;
    }
    #menu .sub-menu li {
        width: auto;
    }

    #menu .sub-menu li a::before {
        content: "↳";
        padding-left: 15px;
        padding-right: 10px;
        margin-top: -4px;
        float: left;
    }

    #menu .sub-menu > li > ul > li a::before {
        padding-left: 38px;
        padding-right: 10px;
    }
}

@media only screen and (min-width: 1025px) {
    #menu .main-menu {
        display: block;
    }
    .menu-right {
        float: right !important;
    }
    #toggle-menu,
    #menu label.drop-icon {
        display: none;
    }

    #menu ul span.drop-icon {
        display: inline-block;
    }

    #menu li {
        float: left;
        border-width: 0 1px 0 0;
    }

    #menu .sub-menu li {
        float: none;
    }

    #menu .lemb .sub-menu {
        width: auto !important;
    }

    #menu .menu-right .sub-menu {
        border-width: 0;
        margin: 0;
        position: absolute;
        top: 100%;
        left: auto;
        right: 0 !important;
        width: 16em;
        z-index: 3000;
    }

    #menu .sub-menu {
        border-width: 0;
        margin: 0;
        position: absolute;
        top: 100%;
        left: 0;
        width: 16em;
        z-index: 3000;
    }

    #menu .sub-menu,
    #menu input[type="checkbox"]:checked + .sub-menu {
        display: none;
    }

    #menu .sub-menu li {
        border-width: 0 0 1px;
    }

    #menu .sub-menu .sub-menu {
        top: 0;
        left: 100%;
    }

    #menu li:hover > input[type="checkbox"] + .sub-menu {
        display: block;
    }
}

/* Efeito menu Hamburguer*/

/* Icon 1 */

#nav-icon {
    width: 20px;
    height: 15px;
    float: right;
    margin-top: 2px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
}

#nav-icon span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #596d98;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
}

/* Icon 3 */

#nav-icon span:nth-child(1) {
    top: 0px;
}

#nav-icon span:nth-child(2),
#nav-icon span:nth-child(3) {
    top: 5px;
}

#nav-icon span:nth-child(4) {
    top: 10px;
}

#nav-icon.open span:nth-child(1) {
    top: 5px;
    width: 0%;
    left: 50%;
}

#nav-icon.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

#nav-icon.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

#nav-icon.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
}
