#valorodonto table thead tr th {
    padding: 10px 0 12px 13px !important;
}


#valorodonto table thead tr th,
#valorodonto table tbody tr td {
    white-space: nowrap;
    vertical-align: middle !important;
}

#valorodonto table tbody tr td {
    padding:0 15px !important;
    width:15%;
}

#valorodonto table .form-group {
    margin-bottom: 0;
}

#valorodonto table input {
    border:0 !important;
}

#valorodonto input[type=text], 
#valorodonto input[type=date], 
#valorodonto input[type=email], 
#valorodonto input[type=password], 
#valorodonto input[type=number] {
    padding:22px 0;
    background: transparent;
}

#valorodonto button[type="submit"] {
    margin-right: 5px;
}